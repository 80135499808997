/* ModalTable.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  /* Background overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it is on top */
  visibility: visible;
  /* Ensure visibility */
}

.modal-content {
  background-color: white !important;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh; /* Limit height to 80% of viewport */
  overflow-y: auto;  /* Enable scrolling if content exceeds height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  /* Ensure opacity is set */
}

.modal-body {
  overflow-y: auto;
  flex-grow: 1; /* Ensure body takes up remaining space */
}

.modal-title {
  margin-bottom: 15px;
  /* Space between title and table */
  font-size: 1.5rem;
  /* Increase font size */
  font-weight: bold;
  /* Make the title bold */
  color: #333;
  /* Dark color for contrast */
  padding: 10px;
  /* Add padding for better appearance */
  background: #f0f0f0;
  /* Light background to enhance visibility */
  border-radius: 5px;
  /* Round the corners of the title background */
}

.close-button {
  background: rgb(96 93 175);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-top: 20px;
  /* Space between button and table */
}

.close-button:hover {
  background: rgb(96 93 175);
}

.rdt_TableCell {
  white-space: normal;
  word-break: break-word;
}