.tab-pane {
  .empty_msg {
    padding-top: 64px;
    text-align: center;
    color: #595959;
    font-size: 14px;
    font-weight: 500;
  }
}

.nav-tabs .nav-link {
  margin-bottom: 0px;
}

.storage-location-container {
  .location-col {
    max-width: 312px;
    width: 100%;
    padding-right: 12px;

    .child-list {
      padding-left: 20px;
    }

    .open {
      & > .input-group {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 20px;
          height: 100%;
          background: transparent;
          border-left: 2px solid #eee;
          top: 84%;
          left: 8px;
          border-bottom: 2px solid #eee;
          border-radius: 10px;
        }

        ul li a:hover {
          background: unset;
        }
      }
    }

    .multi-child {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 12px;
        background: transparent;
        height: 100%;
        top: -6%;
        border-left: 2px solid #eee;
        left: 8px;
        border-radius: 10px;
      }

      &-open {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 21px;
          background: transparent;
          border-bottom: 2px solid #eee;
          top: -5%;
          left: -12px;
          height: 28px;
          border-radius: 10px;
        }
      }
    }

    .input-group {
      cursor: pointer;

      .input-group-bx {
        background-color: #f8f8f8;
        border-radius: 0px 5px 5px 0px;

        .dropdown {
          top: -2px;
          right: 8px;

          .btn {
            border-radius: 8px;
            background: #eee;
            line-height: 1;
            padding: 3px 3px;
            border: none;
            width: 24px;
            height: 24px;

            &::after {
              content: none;
            }
          }
        }
      }

      .selected-item-bg {
        background: #f9f5ff;
        color: white;
      }
      .not-selected-bg {
        background: #ffffff;
      }
    }
  }
}

.storage-location-dropdown {
  top: -6px !important;
  right: 0 !important;
  margin-left: auto !important;
}

.storage-content-container {
  .breadcrumb-box {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    nav {
      .breadcrumb {
        margin-bottom: 0 !important;
        display: flex;
        gap: 4px !important;
        align-items: center !important;
        .breadcrumb-item {
          padding: 0 !important;

          a {
            color: #595959 !important;
            text-decoration: none !important;
            padding-left: 4px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }

          &.item-color {
            a {
              color: #605daf !important;
            }
          }

          &::before {
            content: '';
            padding: 0 !important;
            width: 24px;
            height: 24px;
            background: url('../images/right-arrow.svg'), no-repeat, left top;
          }
        }
      }
    }
  }
  .storage-content-inner {
    .d-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

// textarea style

.delete-email-templates-textarea {
  border-width: 0 !important;
  border-color: #ffffff !important;
  border-style: none !important;
  background-color: transparent !important;
  width: 300px !important;

  &.border {
    border-width: 0 !important;
    border-color: #ffffff !important;
    border-style: none !important;
    min-height: 150px;
    color: #595959 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 150% !important;
    padding: 0;
  }
}
