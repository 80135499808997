.hema-form-dropdown-custom .btn {
  background: #fff !important;
  width: 100%;
  border: 1px solid #dee2e6 !important;
  font-size: 12px;
  padding: 9px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 5px;
  // color: #232323;
  text-align: left;
  &:disabled {
    background-color: #f1f1f1 !important;
    color: #232323;
  }
}
// .country_select {
//   .hema-form-dropdown-custom {
//     .search-wrapper.searchWrapper {
//       width: 263px;
//       overflow-x: scroll;

//       &::-webkit-scrollbar:horizontal {
//         height: 3px;
//         background-color: rgba(222, 226, 230, 0);
//       }
//       &::-webkit-scrollbar-thumb:horizontal {
//         background-color: rgb(142, 145, 145);
//         border-radius: 5px;
//       }
//     }
//   }
// }
.hema-form-dropdown-custom {
  .search-wrapper.searchWrapper {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    // min-height: 35px;
    padding: 5px 10px;
    // overflow-x: scroll;
    height: auto;
    &::-webkit-scrollbar:horizontal {
      // height: 3px;
      background-color: rgba(222, 226, 230, 0);
    }
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: rgb(142, 145, 145);
      border-radius: 5px;
    }

    .chip {
      margin: 0 !important;
    }

    & input {
      margin: 0 !important;
      opacity: 0 !important;
    }
  }

  &.dropdown-selected {
    .search-wrapper.searchWrapper {
      & input {
        opacity: 1 !important;
        color: #232323;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 120% !important;
        padding: 4px 0 !important;

        &::placeholder {
          color: #989898;
        }
      }
    }
  }
}

.hema-form-dropdown-custom,
.hema-form-dropdown-custom.dropdown-togglebtn.btn-primary {
  .placeholderColor {
    // color: #989898 !important;
    color: #a3a9af !important;
  }

  .nonePlaceholderColor {
    color: #232323 !important;
  }
}

.hema-form-dropdown-custom .btn:hover,
.hema-form-dropdown-custom .btn:first-child:active,
.hema-form-dropdown-custom .btn.show {
  background: transparent;
  color: #150303;
  border: 1px solid #dee2e6;
}

.hema-form-dropdown-custom .dropdown-menu {
  width: 100%;
  padding: 0 !important;
  max-height: 400px;
  overflow: auto;

  .dropdown-item {
    padding: 10px 10px;

    color: #232323;

    &:active,
    &:disabled {
      background: #f1f1f1 !important;
      color: #232323 !important;
    }
  }
}

.hema-form-dropdown-custom {
  .dropdown-detail-m {
    // z-index: 99999;
    // position: absolute !important;
    // inset: inherit !important;
    // transform: inherit !important;
    // width: fit-content !important;

    .dropdown-item {
      color: #232323;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

// create assembly dropdown
.kit-dropdown-custom.dropdown {
  .kit-dropdown-custom {
    background: #fff;
    border-color: #f1f1f1;

    &:active,
    &:disabled {
      background: #fff !important;
      color: #232323 !important;
      border-color: #f1f1f1;
    }
  }
  .custom-dropdown-kit2 {
    word-break: break-word !important;
    > input {
      padding-right: 20px !important;
    }
  }
}
.kit-dropdown-custom .dropdown-menu {
  width: 100%;
  padding: 0 !important;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  .dropdown-item {
    padding: 10px 10px;
    color: #232323;
  }
}
.kit-dropdown-custom {
  border: none !important;
  width: 100% !important;

  .placeholderColor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #595959;
  }

  & input {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
  }
  &.dropdown-toggle {
    padding: 3px 6px !important;
    display: flex;
    align-items: center;

    span {
      top: 9px !important;
    }
  }
  .dropdown-toggle {
    padding: 3px 6px !important;
    display: flex;
    align-items: center;
    span {
      top: 9px !important;
    }
  }
  &.custom-dropdown-kit {
    white-space: pre-wrap !important;
    > div {
      word-break: break-word !important;
    }
    .dropdown-toggle {
      padding: 3px 26px 3px 6px !important;
    }
    .nonePlaceholderColor {
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 150% !important;
      color: #989898 !important;
    }
  }

  &.custom-dropdown-kit-Details {
    .nonePlaceholderColor,
    .placeholderColor {
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 150% !important;
      color: #232323 !important;
    }
  }
  &.custom-dropdown-kit-Details-2 {
    .nonePlaceholderColor {
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 150% !important;
      color: #232323 !important;
    }
  }
}

.kit-dropdown-custom-number {
  input {
    display: flex;
    align-items: center;
    padding: 3px 10px !important;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 150% !important;
    color: #232323;
  }
}
.dropdown-item.active,
.dropdown-item:active {
  background: #f1f1f1 !important;
  color: #232323 !important;
}

.hema-form-dropdown-custom {
  .search-wrapper {
    .chip {
      display: inline-flex;
      align-items: center;
      padding: 4px 7px;
      gap: 2px;
      width: fit-content;
      height: auto;
      background: #ebebeb;
      border-radius: 5px;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      color: #232323;

      .custom-close {
        cursor: pointer;
      }
    }
  }

  //
  .optionContainer {
    .option {
      color: #232323 !important;
      font-family: Inter;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 150% !important;
      &.highlightOption.highlight {
        color: #232323 !important;
        background: #ebebeb !important;
      }
      &:hover {
        color: #232323 !important;
        background: #ebebeb !important;
      }
    }
  }
}

// checkbox style
.file-checkbox {
  width: 20px !important;
  height: 20px !important;
  clipPath: circle(45% at 50% 50%);
}
