.left-sidebar-protocol {
  .accordion {
    .accordion-item {
      border: none !important;
      .accordion-button:focus {
        box-shadow: none;
      }
      .accordion-body {
        padding: 0px;
      }
      .accordion-header {
        button {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;

          color: #353b4a;
          padding: 8px 16px;
        }
        .accordion-button:not(.collapsed) {
          background-color: #f9f5ff;
          color: #605daf;
        }
      }
      .accordion-button::after {
        background-size: 12px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

//

// audit table design

.audit-table-design {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        min-height: 40px !important;
      }
    }

    .rdt_TableBody {
      .rdt_TableRow {
        border-bottom: none !important;
        min-height: 40px !important;
        &:not(:first-child) {
          border-top: 1px solid #dee2e6 !important;
        }

        .rdt_TableCell {
          padding-left: 6px !important;
        }
      }
    }
  }
}
// audit accordion
.audit_accordion {
  // padding-bottom: 16px;
  .accordion {
    .accordion-item {
      border: none !important;
      padding-left: 16px !important;
      .accordion-header {
        .accordion-button,
        .accordion-button.collapsed {
          padding: 6px 12px 6px 0 !important ;
          border: none !important;
          background-color: #ffffff !important;
          color: #000;
          box-shadow: none !important;
          border-radius: 0 !important;
          &:focus {
            box-shadow: none;
          }

          &::after {
            display: none !important;
          }
        }

        .accordion-button {
          .not-expand-icon {
            display: none;
          }
          .expand-icon {
            display: block;
          }
        }
        .accordion-button.collapsed {
          .not-expand-icon {
            display: block;
          }
          .expand-icon {
            display: none;
          }
        }
      }
      .accordion-body {
        padding: 2px 0 0px 16px !important;
        hr {
          display: none;
        }
        div {
          .audit_box {
            background: transparent;
            padding-top: 25px;
            .audit_accordion {
              .accordion {
                .accordion-item {
                  .accordion-header {
                    .accordion-button {
                      background: #605daf;
                      color: #ffffff;
                      border-radius: 0 !important;
                      &::after {
                        display: none !important;

                        // background-image: url("../../../assets/images/download.svg");
                      }
                    }

                    .accordion-button.collapsed {
                      &::after {
                        display: none !important;
                      }
                    }
                    .accordion-button {
                      .not-expand-icon {
                        display: none;
                      }
                      .expand-icon {
                        display: block;
                      }
                    }
                    .accordion-button.collapsed {
                      .not-expand-icon {
                        display: block;
                      }
                      .expand-icon {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .main_audit_box {
          margin: 0;

          .audit-header,
          .audit-body {
            padding: 6px 10px !important;
          }

          .audit-body-1-2 {
            padding: 0 10px !important;
          }
        }
        .object_audit_div {
          div {
            &:nth-child(5) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
// .main_audit_box {
//   .audit_box {
//     &:first-child {
//       padding-top: 25px;
//     }
//   }
// }
.audi {
  background: #fbfbfb;
}
