.inventory-tabs {
  .nav-tabs {
    --bs-nav-tabs-border-width: inherit;
    --bs-nav-tabs-border-color: inherit;
    --bs-nav-tabs-border-radius: inherit;
    --bs-nav-tabs-link-hover-border-color: inherit;
    --bs-nav-tabs-link-active-color: inherit;
    --bs-nav-tabs-link-active-bg: inherit;
    --bs-nav-tabs-link-active-border-color: inherit;
    border-bottom: 1px solid #f2f2f2 !important;

    .nav-item {
      .nav-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #414753;
        min-width: 88px;
        padding: 4px 5px 4px 8px;
        position: relative;
        margin-right: 16px;

        &.active {
          color: #775fd5;
          border-bottom: 3px solid #775fd5;
          // padding: 4px 6px 5px 0;
        }
      }
    }
  }
}

.tab-space-left {
  .nav-tabs {
    .nav-item {
      &:first-child {
        margin-left: 40px !important;
      }
    }
  }
}
.tabs_head_space {
  .nav-tabs {
    margin-left: 16px;
    margin-right: 16px;

    .nav-item {
      .nav-link {
        height: 40px !important;
      }
    }
  }
}

// Action tabs
.action-inventory-tabs {
  .nav-item {
    .nav-link {
      min-width: auto !important;
    }
  }
}
.permission-table {
  .sc-dmXXqC.dLPHLX {
    .sc-fLsdZI.fOcRrp {
      .sc-beySbM.dehtZj.rdt_Table {
        .sc-guDLRT.jnKa-Dc.rdt_TableHead {
          .sc-dmyDGi.eYOasi.rdt_TableHeadRow {
            .rdt_TableCol {
              // max-width: inherit !important;
              // min-width: inherit !important;

              &:first-child {
                .fpbtCN {
                  width: 710px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

// order-detail-table-kR

.order-detail-table-kR {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        border-bottom: none !important;
        .rdt_TableCol {
          &:first-child {
            flex: 0 0 400px !important;
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        .rdt_TableCell {
          &:first-child {
            flex: 0 0 400px !important;
          }
        }
      }
    }
  }
}

// notebook_table

.notebook_table {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(2) {
      max-width: clamp(330px, 48vw, 850px);
      min-width: clamp(330px, 48vw, 850px);
    }
  }
}

// custom-switch

.custom-switch {
  .react-switch {
    width: 36px;
    height: 20px;
    border-radius: 15px;
    .react-switch-bg {
      width: 36px !important;
      height: 20px !important;
      & > div {
        display: none !important;
      }
    }
    .react-switch-handle {
      width: 16px !important;
      height: 16px !important;
      top: 2px !important;
    }
    &.true-switch {
      .react-switch-handle {
        transform: translateX(18px) !important;
      }
    }
    &.false-switch {
      .react-switch-bg {
        background: #f1f1f1 !important;
      }
      .react-switch-handle {
        transform: translateX(2px) !important;
        box-shadow: 0px 1px 2px rgba(35, 35, 35, 0.06),
          0px 1px 3px rgba(35, 35, 35, 0.1) !important;
      }
    }
  }
}

// role_setting_table

.role_setting_table {
  .rdt_Table {
    .rdt_TableHeadRow {
      .rdt_TableCol:first-child {
        min-width: 400px !important;
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        border: none !important;
      }
    }
  }
}

// form OR style
.or-style {
  .w-full.flex.undefined {
    margin: 25px 0;
    border-bottom: none !important;
    justify-content: center !important;

    h2 {
      color: #454545;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    div {
      display: none !important;
    }
  }
}
