@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';

.rdt_TableRow > div:nth-of-type(1) > button:nth-of-type(1):disabled {
  display: none;
}
.nav-link.disabled {
  opacity: 0.4;
}

/* .sc-fFGjHI div:first-child {
  white-space: normal !important;
} */
