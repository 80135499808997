@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import './tabs.scss';
@import './storagelocations.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

$applyStyles: true;

*,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: 'Inter', sans-serif !important;
}

// login
.login-banner-bg {
  background-image: url('../images/login-banner.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100vh;
    background: linear-gradient(71.65deg, #392fb4 0.38%, #781fc2 69.03%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      68.5deg,
      rgba(57, 47, 180, 0) 50.22%,
      #ef00de 103.41%
    );
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

.hema-ellipse-dropdown {
  .dropdown-toggle {
    padding: 0;
    border: none;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  .dropdown-menu {
    width: max-content;
  }

  .dropdown-item {
    padding: 10px 15px;

    color: #232323;

    &:active {
      background: #f1f1f1;
      color: #232323;
    }
  }
}

// package dropdown
.package-ellipse-dropdown {
  .dropdown-menu {
    // min-width: 60px;
    // transform: translate3d(0px, 38.4px, 0px) !important;
    // inset: 0px 0px auto auto !important;
    min-width: max-content;
  }

  .dropdown-item {
    padding: 5px 15px;

    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent !important;
    }
  }
}

.WQNxq {
  overflow: visible !important;
}

.hover-language-icon-delete {
  &:last-child {
    .separator {
      display: none;
    }
  }
}

.hover-language-icon-delete:hover p ~ div {
  display: block;
}

.blue-text {
  color: blue;
}

.blue-text {
  color: blue;
}

.bold-text {
  font-weight: 600;
}

// shipping_table

.shipping_table {
  .check_box {
    & input {
      width: 20px;
      height: 20px;
    }
  }
}

// preparation tab checkbox
.pre_table {
  .rdt_Table {
    .rdt_TableBody {
      .rdt_TableRow {
        .check_box {
          input {
            &[type='checkbox'] {
              content: '' !important;
              width: 20px !important;
              height: 20px !important;
              border-radius: 5px;
              border: 1px solid #dee2e6;
              background: #fff;
            }

            &[type='checkbox']:checked:before {
              content: '' !important;
              width: 20px !important;
              height: 20px !important;
              background-image: url('../../assets/images/check-toggle.svg');
              background-color: #ffffff;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              display: block;
            }
          }
        }
      }
    }
  }
}

// .kit_details_table {
//   .dLPHLX {
//     overflow: inherit !important;
//   }
// }
.kit_details_table > div {
  overflow: inherit !important;
}

///

.parent-level-tree {
  .parent_checkbox {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #232323;

    &[type='checkbox'] {
      content: '' !important;
      width: 19px !important;
      height: 19px !important;
      border: 1px solid #d0d0d0;
      border-radius: 5px;
    }

    &[type='checkbox']:checked:before {
      content: '' !important;
      width: 20px !important;
      height: 20px !important;
      background: #fff;
      background-image: url('../../assets/images/check-toggle.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
    }
  }
}

.lower-level-tree {
  .rct-node .rct-node-icon {
    display: none;
  }

  .rct-node .rct-node-folder {
    padding-left: 0;
  }

  .rct-node .rct-text {
    margin-bottom: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #232323;

    .rct-title {
      padding: 0 !important;
    }

    .rct-icon {
      width: 20px;
      height: 20px;
    }

    .rct-checkbox {
      padding: 0 7px !important;
      width: auto;
      height: auto;
    }

    .rct-icon.rct-icon-half-check::before {
      content: '' !important;
      width: 20px !important;
      height: 20px !important;
      background-image: url('../../assets/images/sub-Toggle.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
    }

    .rct-icon.rct-icon-uncheck::before {
      content: '' !important;
      width: 20px !important;
      height: 20px !important;
      border: 1px solid #d0d0d0;
      border-radius: 5px;
      display: block;
    }

    .rct-icon.rct-icon-check::before {
      content: '' !important;
      width: 20px !important;
      height: 20px !important;
      background-image: url('../../assets/images/check-toggle.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
    }
  }
}

///

///
.hema-form-dropdown-custom {
  .kitbuilder_dropdownshow {
    width: 468px;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding-top: 26px;

    .rdt_Table {
      border: none;

      .rdt_TableBody {
        padding-top: 0;

        .rdt_TableRow {
          border-bottom-width: 1px !important;
          border-style: solid;
          border-color: #dee2e6;
          border-radius: 0;

          &:hover {
            background: #f1f1f1;
          }
        }
      }
    }

    .dropdown-item-data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #dee2e6;
    }
  }
}

///

// QC-border

.QC-border {
  border: none !important;
  border-bottom: 1px dashed #dee2e6 !important;
}

// pagination wrapper

.pagination-wrapper {
  .dropdown {
    width: fit-content;

    .hema-form-dropdown-custom {
      width: auto;
      min-width: 65px;
      height: 40px;
      padding: 11px 12px;
      background: transparent;
      border: 1px solid #dee2e6 !important;
      box-shadow: 0px 1px 2px rgba(35, 35, 35, 0.05);
      border-radius: 5px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;

      .placeholderColor {
        color: #232323 !important;
      }
    }
  }
}

//  Action Checkbox
.action_checkbox[type='radio'] {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

//  PACKAGE Checkbox
.item_package[type='checkbox'] {
  width: 14px;
  height: 20px;
  flex-shrink: 0;
}

// hemato-dropdown-btn

.hemato-dropdown-btn {
  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    width: fit-content;
    height: 40px;
    background: #605daf;
    box-shadow: 0px 1px 2px rgba(35, 35, 35, 0.05);
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #ffffff;

    &:hover,
    &.show {
      background: #605daf;
      color: #ffffff;
    }
  }
  .dropdown-menu {
    padding: 10px 0 !important;

    .dropdown-item {
      display: flex;
      height: 41px;
      padding: 10px;
      align-items: center;
      align-self: stretch;
      color: #232323;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}

// number input

input[type='number'],
input[type='date'] {
  padding-right: 10px !important;
}

.remove-img-kit-order img {
  display: none;
}

.number-input-style {
  span {
    width: fit-content;
    height: 75%;
    background: white;
    padding-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    top: 5px !important;
    right: 25px !important;
  }
}

// file-progress

.file-progress {
  width: 100%;
  height: 72px !important;
  background: #ffffff !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 5px !important;

  .progress-bar {
    background-color: #d7dbe0 !important;
    padding: 16px !important;
  }
}

// allert_design

.allert_danger {
  background: #f7eaea !important;
  border-width: 1px 1px 4px 1px !important;
  border-style: solid !important;
  border-color: #e8aeae !important;
  border-radius: 0 !important;
}

.allert_success {
  background: #e4f1d9 !important;
  border-width: 1px 1px 4px 1px !important;
  border-style: solid !important;
  border-color: #c4dda5 !important;
  border-radius: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
.kit-dropdown-custom-number input[name='quantity']::-webkit-outer-spin-button,
.kit-dropdown-custom-number input[name='quantity']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[name='namelabPhoneNmber']::-webkit-outer-spin-button,
input[name='namelabPhoneNmber']::-webkit-outer-spin-button,
input[name='labPhoneNmber']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[name='phoneNo']::-webkit-outer-spin-button,
input[name='phoneNo']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[name='phone']::-webkit-outer-spin-button,
input[name='phone']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[name='IORPhone']::-webkit-outer-spin-button,
input[name='IORPhone']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[name='IORPhone'] + span,
input[name='brokerPhone'] + span,
input[name='phoneNo'] + span,
input[name='namelabPhoneNmber'] + span {
  right: 8px !important;
}
input[name='brokerPhone']::-webkit-outer-spin-button,
input[name='brokerPhone']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// inventory_table

.inventory_table {
  .rdt_Table {
    .rdt_TableBody {
      .rdt_TableRow {
        border: none !important;
        box-shadow: 0px 3px 3px rgba(207, 207, 207, 0.25);
        border-radius: 5px;
      }
    }
  }

  .table_lable_hed {
    label {
      gap: 6px;

      span.font-medium {
        min-width: 72px;
      }
    }
  }

  .table_lable_hed_last {
    label {
      gap: 7px;

      span.font-medium {
        min-width: 143px;
      }
    }
  }
}

.doc-template-hema .hema-form-dropdown-custom img {
  display: none;
}

//

.kit_builder_table {
  .rdt_Table {
    .rdt_TableHeadRow {
      display: none;
      border-bottom: none;
    }

    .rdt_TableBody {
      padding-top: 20px;

      .rdt_TableRow {
        border: 1px solid #dee2e6;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 5px 0;
      }
    }
  }
}

// hema-single-select

.hema-single-select {
  .css-1hb7zxy-IndicatorsContainer {
    span.css-1u9des2-indicatorSeparator {
      display: none !important;
      width: 0 !important;
      background-color: inherit !important;
    }

    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
      padding: 0 6px 0 0 !important;
    }
  }

  .css-1xc3v61-indicatorContainer,
  .css-15lsz6c-indicatorContainer {
    padding: 0 6px 0 0 !important;
  }

  span.css-1u9des2-indicatorSeparator {
    display: none !important;
    width: 0 !important;
    background-color: inherit !important;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 35px !important;
    border: 1px solid #dee2e6 !important;
    box-shadow: none !important;

    .css-1fdsijx-ValueContainer {
      .css-1dimb5e-singleValue {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
      }
    }
  }
}

// inventory_management_ready_table

.inventory_management_ready_table {
  .rdt_Table {
    margin-top: 10px !important;

    .rdt_TableHead {
      border-top: 1px solid #dee2e6;

      .rdt_TableHeadRow {
        .rdt_TableCol {
          &:last-child {
            -webkit-box-flex: 1;
            max-width: 100%;
            min-width: 130px;
            padding-left: 16px;
            padding-right: 16px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

// remove expandable row for filter

.hema-rdt {
  .rdt_TableBody {
    .rdt_TableRow {
      &:first-child .kqyAeX svg {
        margin-bottom: 8px;
      }

      .kqyAeX:hover {
        background-color: inherit;
      }

      .kqyAeX:active {
        background-color: inherit;
      }
    }
  }
}

// Request Acknowledgment input
.acknowledgement-input-div {
  > div {
    .rti--container {
      .rti--input {
        width: 100%;
        height: auto;
      }
    }
  }
}

// type_table

.table {
  margin-bottom: 0 !important;
}

// remove expandable row for filter

.rdt_TableBody {
  .rdt_TableRow {
    &:first-child .kqyAeX svg {
      margin-bottom: 8px;
    }

    .kqyAeX:hover {
      background-color: inherit;
    }

    .kqyAeX:active {
      background-color: inherit;
    }
  }
}

// inventory_management table
.inventory_management {
  .rdt_Table {
    max-width: 529px;
  }
}

// table header heading center

.kit-table-header-center.not_lost {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            .fpbtCN {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.kit-table-header-center {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          &:not(:first-child, :last-child) {
            .fpbtCN {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

// Generate Airway Bill

.airway-bill-table {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        min-height: 40px !important;

        .rdt_TableCol {
          &:first-child {
            flex: 0 0 151px !important;
          }
        }
      }
    }

    .rdt_TableBody {
      .rdt_TableRow {
        min-height: 40px !important;

        .rdt_TableCell {
          &:first-child {
            flex: 0 0 151px !important;
          }

          input {
            padding: 3px 6px !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            outline: none !important;
          }
        }
      }
    }
  }
}

// tags input style

.hema-tag-input {
  .rti--container {
    width: 100%;
    padding: 5px 10px !important;

    .rti--tag {
      padding: 4px 8px;

      span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #232323;
      }

      button {
        width: 14px;
        height: 14px;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2px;
      }
    }

    input {
      width: auto;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
    }

    input:disabled {
      // display: none;
    }

    input[name='greenLightContact'] {
      width: auto;
      //     display: block !important;
    }
  }
}

.green-light-attachments-tags {
  .hema-tag-input {
    .rti--container {
      width: 100%;

      input[name='greenLightContact'] {
        width: auto;
      }
    }
  }
}

.hema-tag-input.hideborder {
  .rti--container {
    width: 100%;
    padding: 5px 10px !important;
    border: none !important;
  }

  input {
    outline: none !important;
  }

  .rti--container:focus-within {
    box-shadow: none;
  }
}

.hema-tag-input.hideclose {
  .rti--tag {
    button {
      display: none;
    }
  }
}

.hema-tag-input.hideinput {
  .rti--input {
    display: none;
  }
}

//filter css
.hdxa-rdt-filter {
  .rdt_TableHeadRow {
    .rdt_TableCol_Sortable {
      align-items: flex-start;
      opacity: 1;

      &:hover {
        opacity: 1 !important;
        background-color: none;
      }
    }
  }
}

.responsive_table {
  overflow-x: scroll !important;
}

.selecter-dropdown-text {
  max-width: 288px;

  button {
    div,
    input {
      width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// log kit config css
.lo-kit-config-table {
  .rdt_Table {
    .rdt_TableCol {
      padding-left: 40px;
    }

    .rdt_TableBody {
      .rdt_TableRow {
        justify-content: flex-end;
        margin-left: 0 !important;

        @media (max-width: 1600px) {
          margin-left: 27px !important;
        }

        .sc-hLQRIN {
          // left: 21px;

          flex-shrink: 0;

          button[aria-label='Expand Row'] {
            margin-left: 4px;
            flex-shrink: 0;

            svg {
              margin-bottom: 11px !important;
            }
          }
        }
      }
    }
  }
}

.multiselect-container.disable_ms {
  background-color: #efefef;
}

div[role='columnheader'] {
  opacity: 1 !important;
}

.dropdown-item {
  white-space: normal !important;
  word-break: break-word !important;
}

// request order detail

.request-order-detail {
  .order-date-history {
    input {
      position: relative;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
      background-position: right;
      background-size: auto;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 5px;
      top: 8px;
      width: auto;
    }
  }
}

// all managment table header style
.managment-table-st {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          padding: 0 !important;

          .rdt_TableCol_Sortable {
            .short-icon {
              display: block !important;
            }

            span.sc-kFCsca,
            span.sc-kpDqfm,
            span {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.responsive-table {
  overflow: visible !important;

  @media (max-width: 1100px) {
    overflow: auto hidden !important;
  }
}

.builder-table-st {
  overflow: visible !important;

  @media (max-width: 1100px) {
    overflow: auto hidden !important;
  }

  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .kDZMuE.jvLLbd {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 47%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #dee2e6;
          }
        }

        .rdt_TableCol {
          padding: 0 !important;

          .rdt_TableCol_Sortable {
            .short-icon {
              display: block !important;
            }

            span.sc-kFCsca,
            span.sc-kpDqfm,
            span {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.role-mana-table-style {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol:last-child {
          min-width: 150px !important;
        }
      }
    }
  }
}

// Timer Modal
.modal-md {
  .modal-content {
    max-width: 414px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    .modal-header {
      border-bottom: none;
      justify-content: end;
    }
    .modal-body {
    }
    .modal-footer {
      border-top: none;
      justify-content: center;
      .timer_btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }
  }
}

// Add Inventory Item Nanme field
.hema-form-dropdown-custom,
.hema-form-dropdown-custom.dropdown-togglebtn.btn-primary {
  .placeholderColor {
    overflow: hidden !important;
    word-break: break-word !important;
    white-space: pre-wrap !important;
  }
}

#app-setting-dropdown {
  background: none;
  color: #202020;
  border: 1px solid #DEE2E6 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}